import { PageProps } from "gatsby";
import React from "react";

import { SoftwareMuseScore } from "../../components/pages/SoftwareMuseScore";

import { dnProps } from "../../js/utils";

const SoftwareMusescorePage = (props: PageProps) => (
  <SoftwareMuseScore {...{ ...dnProps, ...props }} />
);

export default SoftwareMusescorePage;
